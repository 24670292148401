import React from 'react';

import { Layout } from '../components/Layout';

const Contact = () => (
  <Layout>
    <h1>Contact</h1>
  </Layout>
);

export default Contact;
